import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const PurpleCallout = props => {
  const { data } = props
  const { title, content, callText, icon, btnText } = data[0]
  return (
    <section>
      <Container>
        <Row>
          <Col lg={{ span: 12, offset: 0 }}>
            <div className="purple-callout-container">
              <Row>
                <Col lg={{ span: 1, offset: 1 }} xs={{ span: 3, offset: 4 }}>
                  <div className="icon-container">
                    <img src={icon} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 7, offset: 0 }} xs={{ span: 10, offset: 1 }}>
                  {title}
                  {content}
                  {callText}
                </Col>
                <Col lg={{ span: 2, offset: 0 }} xs={{ span: 10, offset: 1 }}>
                  <div className="cta-btn-container purple-callout-btn-container">
                    <a href="tel:1-833-693-3627">
                      <button className="cta-btn white-btn">{btnText}</button>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PurpleCallout
