import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import plus from "../../assets/images/Plus.svg"
import plusHovered from "../../assets/images/plus-hovered.svg"
import minus from "../../assets/images/Minus.svg"
import minusHovered from "../../assets/images/minus-hovered.svg"

class ContentDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentShown: false,
      hovered: false,
    }
  }

  handleContentShown = () => {
    this.setState({
      contentShown: !this.state.contentShown,
    })
  }

  handleHovered = val => {
    this.setState({
      hovered: val,
    })
  }

  render() {
    const { data } = this.props
    const { list, content, terms } = data[0]

    const { contentShown, hovered } = this.state

    return (
      <section className="content-dropdown-container">
        {terms && (
          <div className="home-page-link" id="terms_and_conditions"></div>
        )}
        <Container>
          <Row>
            <Col>
              <div
                className="dropdown-btn-container"
                onClick={() => this.handleContentShown()}
                onMouseEnter={() => this.handleHovered(true)}
                onMouseLeave={() => this.handleHovered(false)}
              >
                <p className="extra-bold">
                  Myfembree® Copay Assistance Program: Terms and Conditions
                </p>
                {/* {
                  !contentShown ? <img src={hovered ? plusHovered : plus} /> :
                  <img src={hovered ? minusHovered : minus} />
                } */}
              </div>
              <div
                // className={!contentShown ? 'hidden-content-container hidden-content' : 'hidden-content-container'}
                className="hidden-content-container"
              >
                {list}
                {content}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default ContentDropdown
