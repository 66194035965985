import React from "react"
import Layout from "../Layout"
import { Col, Container, Row } from "react-bootstrap"

import HeaderHero from "../HeaderHero/HeaderHero"
import ContentDropdown from "../ContentDropdown/ContentDropdown"
import ContentDropdownData from "../ContentDropdown/ContentDropdownData.js"
import PurpleCallout from "../PurpleCallout/PurpleCallout"
import PurpleCalloutData from "../PurpleCallout/PurpleCalloutData.js"
import copayCard from "../../assets/images/copay-card.webp"
import copayCardFB from "../../assets/images/copay-card.png"

class CommercialInsuranceContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
    }
  }

  componentDidMount = () => {
    const { handleCurrentPage, handleActiveHeaderDropdown } = this.props
    handleActiveHeaderDropdown("")
    handleCurrentPage("resources")

    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    })
  }

  render() {
    const { ContentDropdownDataJobInsurance1 } = ContentDropdownData

    return (
      <Layout
        canonicalURL="https://www.myfembree.com/commercial-insurance/"
        title="Copay Assistance | Myfembree® (relugolix, estradiol, and norethindrone acetate) Tablets"
        pageTitle="Copay Assistance | Myfembree® (relugolix, estradiol, and norethindrone acetate) Tablets"
        description="See benefits, risks & BOXED WARNING of Myfembree® (relugolix, estradiol, and norethindrone acetate). Learn about Copay assistance, bridge program. T&C apply."
      >
        <HeaderHero
          pageClass={"commercial-insurance"}
          title={"Commercial insurance"}
          subTitleContent={
            <ul className="sage-ul">
              <li>$5 Copay Assistance Program</li>
            </ul>
          }
        />
        <section className="insurance-from-job-mid-container">
          <Container>
            <Row>
              <Col lg={{ span: 9 }}>
                <h2 className="blue">Myfembree Copay Assistance Program</h2>
                <h2 className="purple" style={{ fontWeight: "normal" }}>
                  PAY AS LITTLE AS <strong>$5 per month*</strong>
                </h2>
                <p className="small">*Up to $5,000 annual cap</p>
                <p>
                  The Myfembree Copay Assistance Program can provide copay
                  support to people who are prescribed treatment. Eligible
                  people with commercial insurance may benefit from the program
                  when they pick up their prescription from a retail pharmacy
                  during the cost-sharing portion of their insurance benefit.
                </p>
              </Col>
              <Col lg={{ span: 3 }}>
                <div className="copay-container">
                  <picture>
                    <source
                      srcSet={copayCard}
                      className="copay-card-img"
                      alt="Coming soon"
                      type="image/webp"
                    />
                    <img
                      loading="lazy"
                      src={copayCardFB}
                      className="copay-card-img"
                      alt="Coming soon"
                    />
                  </picture>
                  <div className="cta-btn-container">
                    <a
                      href="https://portal.trialcard.com/myovant/myfembree/copay/"
                      target="_blank"
                    >
                      <button className="cta-btn pink-btn">
                        Enroll or Re-enroll
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <ContentDropdown data={ContentDropdownDataJobInsurance1} />
        <PurpleCallout data={PurpleCalloutData} />
        <section className="after-purple-callout">
          <Container>
            <Row>
              <Col>
                <p>
                  Please see your prescribing doctor or call the Myfembree®
                  Support Program for enrollment assistance. Once you are
                  enrolled, the support program team can provide specific
                  information to help you understand your insurance benefits and
                  coverage for Myfembree.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default CommercialInsuranceContent
