import React from "react"
import { Link } from "gatsby"

const ContentDropdownDataJobInsurance1 = [
  {
    content: (
      <p>
        The Myfembree Copay Assistance Program (“Copay Program”) is for eligible
        patients with commercial prescription insurance for Myfembree. With this
        Copay Program, eligible patients may pay as little as $5 per monthly
        Myfembree prescription (or $15 for a 90-day prescription); subject to
        calendar year maximum on Copay Program assistance of $5,000 per calendar
        year. After the calendar year maximum for Copay Program assistance is
        reached, patient will be responsible for the remaining out-of-pocket
        costs for Myfembree. This Copay Program may not be redeemed more than
        once every 21 days. The Copay Program is not valid for patients whose
        prescription claims are reimbursed, in whole or in part, by any state or
        federal government program, including, but not limited to, Medicaid,
        Medicare, Medigap, Department of Defense (DoD), Veterans Affairs (VA),
        TRICARE, Puerto Rico Government Insurance, or any state patient or
        pharmaceutical assistance program. Offer is not valid for cash-paying
        patients. Patient must be a resident of the U.S., Puerto Rico, or U.S.
        Territories. This Copay Program is void where prohibited by state law
        and on the date an AB rated generic equivalent for Myfembree becomes
        available. Certain rules and restrictions apply. This offer is not
        insurance. This offer cannot be combined with any other coupon, free
        trial, discount, prescription savings card, or other offer. This offer
        is not conditioned on any past or future purchase, including refills.
        Patient and participating pharmacists agree not to seek reimbursement
        for all, or any part of the benefit received by the patient through this
        Copay Program. Patient and participating pharmacists agree to report the
        receipt of Copay Program benefits to any insurer or other third party
        who pays for or reimburses any part of the prescription filled using the
        Copay Program, as may be required by such insurer or third party.
        Myovant Sciences reserves the right to revoke, rescind, or amend this
        offer without notice.
      </p>
    ),
    terms: true,
  },
]

const ContentDropdownDataJobInsurance2 = [
  {
    // list:
    // <ul className='purple-ul content-dropdown-list'>
    //   <li className='extra-bold'>
    //     New and continuing patients who experience delays or interruptions in insurance coverage
    //   </li>
    //   <li className='extra-bold'>
    //     Insurance criteria: Commercial only
    //   </li>
    //   <li className='extra-bold'>
    //     Residency criteria: US residence
    //   </li>
    //   <li className='extra-bold'>
    //     Medical criteria: On-label
    //   </li>
    //   <li className='extra-bold'>
    //     Income criteria: Not applicable
    //   </li>
    //   <li className='extra-bold'>
    //     Duration: 28-day supply at a time; 4 months max consecutive; 1 incidence per calendar year;
    //     patients may restart Bridge in the following year
    //   </li>
    //   <li className='extra-bold'>
    //     Additional terms and conditions apply
    //   </li>
    // </ul>,
    content: (
      <p>
        The Myfembree Bridge Program (“Program”) provides Myfembree at no cost
        for a limited period to eligible patients with commercial insurance who
        have been prescribed Myfembree for an FDA-approved indication, and whose
        insurance coverage is delayed or who experience a temporary lapse in
        coverage. Commercially insured patients are eligible for up to 4 months
        of free product. This Program is not valid for patients whose
        prescription claims are reimbursed, in whole or in part, by any state or
        federal government program, including, but not limited to, Medicaid,
        Medicare, Medigap, Department of Defense (DoD), Veterans Affairs (VA),
        TRICARE, Puerto Rico Government insurance, or any state patient or
        pharmaceutical assistance program. Patients and prescribers must
        complete the Myfembree Support Program enrollment form, and prescribers
        must provide a Myfembree Bridge prescription. Patients will receive
        their drug supply each month for up to 4 months or until they receive
        insurance coverage approval, whichever occurs earlier. Patients must be
        residents of the United States or US Territories. Program is not
        available to patients who are uninsured or where prohibited by law such
        as Massachusetts and Minnesota. Patients may be asked to reverify
        insurance coverage status during the course of the Program. Patients and
        participating prescribers agree not to seek reimbursement for all, or
        any part of the benefit received by the patient through this Program. No
        purchase necessary. Program is not health insurance, nor is
        participation a guarantee of insurance coverage. Other limitations may
        apply. Myovant Sciences reserves the right to rescind, revoke, or amend
        the Program and discontinue support at any time without notice.
      </p>
    ),
  },
]

const ContentDropdownDataGovInsurance1 = [
  {
    // list:
    // <ul className='purple-ul content-dropdown-list'>
    //   <li className='extra-bold'>
    //     New patients who experience insurance delays of 5 days or more
    //   </li>
    //   <li className='extra-bold'>
    //     Insurance criteria: Government insurance only
    //   </li>
    //   <li className='extra-bold'>
    //     Income criteria: Not applicable
    //   </li>
    //   <li className='extra-bold'>
    //     Duration: Up to 2 months supply
    //   </li>
    //   <li className='extra-bold'>
    //     Medical criteria: On-label
    //   </li>
    //   <li className='extra-bold'>
    //     Residency criteria: US residence
    //   </li>
    //   <li className='extra-bold'>
    //     Documents requirement: PSP enrollment form
    //   </li>
    //   <li className='extra-bold'>
    //     Additional terms and conditions apply
    //   </li>
    // </ul>
    // ,
    content: (
      <p>
        The Myfembree Quick Start Program (“Program”) provides a one-month
        supply of Myfembree at no cost to eligible patients with government
        insurance who are newly prescribed Myfembree for an FDA-approved
        indication, and whose insurance coverage is delayed 5 days or more. An
        additional one-month supply of Myfembree is available for such eligible
        patients who face a continued delay in insurance coverage. Patients and
        prescribers must complete the Myfembree Support Program enrollment form,
        and prescribers must provide a Quick Start Program prescription.
        Patients must be residents of the United States and US Territories.
        Patients may be asked to reverify insurance coverage status during the
        course of the Program. Patients and participating prescribers agree not
        to seek reimbursement for all, or any part of, the free product received
        by the patient through this Program. Patients may not count the free
        product received from the Myfembree Support Program as an expense
        incurred for purposes of determining out-of-pocket costs for any plan,
        including true out-of-pocket costs (“TrOOP”) for purposes of calculating
        the out-of-pocket threshold for Medicare Part D plans. No purchase
        necessary. Program is not health insurance, nor is participation a
        guarantee of insurance coverage. Other limitations may apply. Myovant
        Sciences reserves the right to rescind, revoke, or amend the Program and
        discontinue support at any time without notice.
      </p>
    ),
  },
]

const ContentDropdownDataNoInsurance1 = [
  {
    // list:
    // <ul className='purple-ul content-dropdown-list'>
    //   <li className='extra-bold'>
    //     Insurance criteria: Uninsured, underinsured, and functionally uninsured
    //   </li>
    //   <li className='extra-bold'>
    //     Income criteria: 500% FPL
    //   </li>
    //   <li className='extra-bold'>
    //     Medical criteria: On-label
    //   </li>
    //   <li className='extra-bold'>
    //     Residency criteria: US residence
    //   </li>
    //   <li className='extra-bold'>
    //     Documents requirement: PSP enrollment form
    //   </li>
    //   <li className='extra-bold'>
    //     Additional terms and conditions apply
    //   </li>
    // </ul>,
    content: (
      <p>
        The Myovant Sciences Patient Assistance Program (“Program”) provides
        Myfembree at no cost to eligible patients who are prescribed Myfembree
        for an FDA-approved indication. Patients and prescribers must complete
        the Myfembree Support Program enrollment form, and prescribers must
        provide a Patient Assistance Program prescription. Patients must meet
        Program eligibility requirements, which include, but are not limited to,
        lack of insurance coverage for Myfembree, financial criteria and income
        evaluation, and patients must be residents of the United States and US
        Territories. Program requires annual re-evaluation and re-enrollment for
        continued participation. Patients may be asked to reverify insurance
        coverage status during the course of the Program. Patient and
        participating prescribers agree not to seek reimbursement for all, or
        any part of, the free product received by the patient through this
        Program. Patients may not count the free product received from the
        Myfembree Support Program as an expense incurred for purposes of
        determining out-of-pocket costs for any plan, including true
        out-of-pocket costs (“TrOOP”) for purposes of calculating the
        out-of-pocket threshold for Medicare Part D plans. Government health
        insured patients who meet the Program eligibility criteria are eligible
        to receive free product for the entire coverage year, and Myovant
        Sciences will notify patients’ government health insurance plans that
        the patient is enrolled in the Program. No purchase necessary. Program
        is not health insurance, nor is participation a guarantee of insurance
        coverage. Other limitations may apply. Myovant Sciences reserves the
        right to rescind, revoke, or amend the Program and discontinue support
        at any time without notice.
      </p>
    ),
  },
]

const ContentDropdownData = {
  ContentDropdownDataJobInsurance1,
  ContentDropdownDataJobInsurance2,
  ContentDropdownDataGovInsurance1,
  ContentDropdownDataNoInsurance1,
}

export default ContentDropdownData
