import React from "react"

import phone from "../../assets/images/phone-icon.svg"

const PurpleCalloutData = [
  {
    title: (
      <h2 className="green">
        Enrolling in the Myfembree<sup>®</sup> Support Program
      </h2>
    ),
    content: (
      <p className="white">
        Call to speak to dedicated support staff who will guide you through the
        enrollment process.
      </p>
    ),
    callText: (
      <h4 className="white">
        <a href="tel:1-833-693-3627" className="purple-callout-link">
          1-833-MYFEMBREE (1-833-693-3627)
        </a>{" "}
        <span className="unbold">
          {" "}
          Monday–Friday, 8 <sub>AM</sub>–8 <sub>PM</sub> ET.
        </span>
      </h4>
    ),
    icon: phone,
    btnText: "Call Now",
  },
]

export default PurpleCalloutData
