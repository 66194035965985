import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import HeroCommercialDesktop from "../../assets/images/Hero-commercial-desktop.jpg"
import HeroCommercialMobile from "../../assets/images/Hero-commercial-mobile.jpg"
import HeroGovernmentDesktop from "../../assets/images/Hero-government-desktop.jpg"
import HeroGovernmentMobile from "../../assets/images/Hero-government-mobile.jpg"
import HeroNoInsuranceDesktop from "../../assets/images/Hero-noinsurance-desktop.jpg"
import HeroNoInsuranceMobile from "../../assets/images/Hero-noinsurance-mobile.jpg"

const HeaderHero = props => {
  const { title, subTitleContent, pageClass } = props
  return (
    <section className="header-hero-container">
      {pageClass === "commercial-insurance" ? (
        <div className="hero-background-container">
          <div
            className={pageClass + " hero-background hero-background--desktop"}
            style={{ backgroundImage: "url(" + HeroCommercialDesktop + ")" }}
          ></div>
          <div
            className={pageClass + " hero-background hero-background--mobile"}
            style={{ backgroundImage: "url(" + HeroCommercialMobile + ")" }}
          ></div>
        </div>
      ) : pageClass === "government-insurance" ? (
        <div className="hero-background-container">
          <div
            className={pageClass + " hero-background hero-background--desktop"}
            style={{ backgroundImage: "url(" + HeroGovernmentDesktop + ")" }}
          ></div>
          <div
            className={pageClass + " hero-background hero-background--mobile"}
            style={{ backgroundImage: "url(" + HeroGovernmentMobile + ")" }}
          ></div>
        </div>
      ) : pageClass === "no-insurance" ? (
        <div className="hero-background-container">
          <div
            className={pageClass + " hero-background hero-background--desktop"}
            style={{ backgroundImage: "url(" + HeroNoInsuranceDesktop + ")" }}
          ></div>
          <div
            className={pageClass + " hero-background hero-background--mobile"}
            style={{ backgroundImage: "url(" + HeroNoInsuranceMobile + ")" }}
          ></div>
        </div>
      ) : (
        <div></div>
      )}
      <Container>
        <Row>
          <Col lg={{ span: 5 }}>
            <div className="header-hero-content-container">
              <h1 className="white">{title}</h1>
              {subTitleContent}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeaderHero
